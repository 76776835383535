<template>
	<HeaderTemplate />

	<section class="relative mx-auto mt-20 w-full max-w-container sm:px-6 lg:px-10" style="padding-right: 0px;">
		<div class="container-fluid mx-auto">
			<form>
				<div class="grid grid-cols-12">
					<div class="col-span-8 padding-col">
						<div class="grid grid-cols-12">
							<div class="mb-6 col-span-12 padding-col">
								<h2 class="title">Edit Immobiliare</h2>
							</div>
							<div class="mb-6 col-span-12 padding-col" v-if="response.message">
								<div :class="`rounded-sm bg-${response.color}-100 p-4 mb-4`">
									<h3 :class="`text-sm leading-5 font-bold text-${response.color}-800`">{{response.message}}</h3>
								</div>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
								<input type="email" v-model="email" id="email" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default ${v$.email.$error}`" required>
								<span v-if="v$.email.$error">
							        <small :class="`text-${response.color}-800`">Campo obrigatório</small>
							    </span>
							</div>
							<div class="mb-6 col-span-4 padding-col ">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Nome Agenzia Immobiliare</label>
								<input type="text" v-model="nome" id="email" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default ${v$.nome.$error}`" required>
								<span v-if="v$.nome.$error">
							        <small :class="`text-${response.color}-800`">Campo obrigatório</small>
							    </span>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">CAP</label>
								<input type="text" @keyup="searchCep()" v-model="cep" id="email" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default ${v$.cep.$error}`" required>
								<span v-if="v$.cep.$error">
							        <small :class="`text-${response.color}-800`">Campo obrigatório</small>
							    </span>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Indirizzo completo</label>
								<input type="text" id="email" v-model="rua" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default" required>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Città</label>
								<input type="text" id="email" v-model="cidade" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default" required>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Provincia</label>
								<input type="text" id="email" v-model="bairro" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default" required>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Cellulare</label>
								<input type="text" v-model="celular" id="email" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default ${v$.celular.$error}`" required>
								<span v-if="v$.celular.$error">
							        <small :class="`text-${response.color}-800`">Campo obrigatório</small>
							    </span>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="email" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Telefono</label>
								<input type="text" v-model="telefone" id="email" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default`" required>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your password</label>
								<input type="password" v-model="password" id="password" :class="`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 input-default ${v$.password.$error}`" required>
								<span v-if="v$.password.$error">
							        <small :class="`text-${response.color}-800`">Campo obrigatório. Digite no mínimos 6 caracteres</small>
							    </span>
							</div>
							<div class="mb-6 col-span-4 padding-col">
								<button @click.stop.prevent="salva()" type="submit" class="flex items-center justify-center text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-bold rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center button-default">
									<img v-if="spinner.salva" class="w-7 h-7 mr-2" src="../../../assets/spinner.svg"> Salva
								</button>
							</div>
						</div>
					</div>

					<div class="col-span-4 ">
						<div class="grid grid-cols-12">
							<div class="col-span-12 padding-col dados-imob">
								<img class="rounded-full" :src="`https://www.pannelloimmobiliare.it/images/cadastros/100.jpg`">
								<h2 class="title">Nome da Imobiliaria</h2>
								<h3 class="sub-title"><strong>#ID 100</strong></h3>

								<div class="grid grid-cols-3 gap-3 gap-immobi">
									<div class="dados-cad">
										<p>Imóveis</p>
										<span>215</span>
									</div>
									<div class="dados-cad">
										<p>Colaboratori</p>
										<span>4</span>
									</div>
									<div class="dados-cad">
										<p>Piano</p>
										<span>Annuale</span>
									</div>

								</div>

								<div class="grid grid-cols-1 gap-1 infos-imob">
									<div>
										<p>
											<span>Data Cadastro: </span> 12/06/2023
										</p>
										<p>
											<span>Localização: </span>Via Salivoli, 37/5 - Piombino (LI)
										</p>
										<p>
											<span>Telefono: </span> +39 547863215
										</p>
										<p>
											<span>E-mail: </span> email@email.com
										</p>
										<p>
											<span>Site: </span> <a href="https://beta.pannelloimmobiliare.it/100" target="_blank">https://beta.pannelloimmobiliare.it/100</a>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>


				</div>

				<div class="grid grid-cols-12">
					
				</div>
			</form>
		</div>
	</section>

	<Footer />
</template>

<script>
	import HeaderTemplate from '@/components/Header.vue';
	import Footer from '@/components/Footer.vue';
	import axios from 'axios';
	import useValidate from '@vuelidate/core'
	import { required, email, minLength, sameAs } from '@vuelidate/validators'

	export default {
		name: 'Edit',
		components: {
			HeaderTemplate,
			Footer
		},
        data: function(){
			return {
				v$: useValidate(),
				classes: true,
				classNames: {
					valid: 'is-valid',
					invalid: 'is-invalid'
				},
				nome: '',
				email: '',
				celular: '',
				telefone: '',
				password: '',
				cep : '',
				cidade : '',
				rua : '',
				bairro : '',
				response: {
					color: '',
					message: '',
				},
				spinner: {
					salva: false,
				}
			}
		},
		methods : {
			searchCep: function(){
				if(this.cep.length >= 5) {
	                axios.get('/v1/busca-cap?cap='+this.cep+'')
	                .then((response) => {
	                    this.cidade = response.data.cidade;
	                    this.rua = response.data.rua;
	                    this.bairro = response.data.bairro;
	                })
	                .catch(function (error) {
	                    console.log(error);
	                });
	            }
            },
			salva(){
				this.resetResponse();
				this.spinner.salva = true;
				this.v$.$validate() // checks all inputs
      			if (!this.v$.$error) {
					axios.post('/v1/login').then((response) => {
						this.spinner.salva = false;
					}).catch((e) => {
						this.spinner.salva = false;
						this.response.message = 'Ocorreu algum problema.';						
					});
				}else{
					this.spinner.salva = false;
					this.response.color = 'red';
					this.response.message = 'Campos obrigatórios';
				}

			},

			resetResponse(){
				this.response.message = '';
			}
		},
		validations() {
		    return {
		    	email: { required },
		    	nome: { required },
		    	celular: { required },
		    	password: { required, minLength: minLength(6) },
		    	cep: { required },
		    }
		},
	}


</script>